import React, {useEffect} from 'react';
import Layout from '../components/Layout';
import SimpleHeaderModule from '../components/SimpleHeaderModule';
import {CTADestination} from '../include/Types';

const Destinations: Record<CTADestination, string> = {
  WebApp: 'https://app.miter.co/?src=miterco',
  ChromeExtension: 'https://chrome.google.com/webstore/detail/miter-calendar/apfbllfpenpdgffjkgiidmgnaalplkog',
  ZoomApp: 'https://marketplace.zoom.us/apps/4gAucbSMSAi4qaaQPmXatA',
};

export default () => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const destination: CTADestination = (url.searchParams.get('dest') as CTADestination) || 'ChromeExtension';
    window.location.replace(Destinations[destination]);
  }, []);

  return (
    <Layout pageTitle="Install Miter" description="Install Miter for web, Zoom, or Google Calendar">
      <SimpleHeaderModule title="Redirecting to Miter">One moment please...</SimpleHeaderModule>
    </Layout>
  );
};
